import React from 'react';

const styleIndex = () => {
  return (
    <style>{`
        body {
          background: #FFFFFF;
        }
    `}</style>
  );
};
export default styleIndex;
