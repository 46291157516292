import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import styles from '../../styles/components/ArticleCard.module.scss';

import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
  LinkedinShareButton,
  EmailShareButton,
} from 'react-share';
import classNames from 'classnames';
import {
  Facebook,
  Twitter,
  Reddit,
  Linkedin,
  EnvelopeFill,
  Slack,
  Discord,
  X,
} from 'react-bootstrap-icons';
import { ToastContainer, toast } from 'react-toastify';
import { postData } from '../shared/global_functions';
import { UnlockedArticle } from '../../additional';
import { AnalyticsEvent, track } from '../shared/analytics';

export interface ShareModalProps {
  unlockedArticle: UnlockedArticle;
  show: boolean;
  onHide: () => Promise<void>;
}

const SHARE_MESSAGE =
  'Check out this paywall-free article I unlocked with https://zette.com: ';

const TWITTER_SHARE_MESSAGE =
  'I unlocked this article with @Zette and I want to share it with you';

const SHARE_TITLE = 'Article unlocked!';

export default function ShareModal({
  unlockedArticle,
  show,
  onHide,
}: ShareModalProps) {
  const [urlShare, setUrlShare] = useState('');

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://teams.microsoft.com/share/launcher.js';
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [show]);

  useEffect(() => {
    const loadInfo = async () => {
      try {
        if (!unlockedArticle || !unlockedArticle.id) {
          return;
        }

        const { data } = await postData({
          url: `/shared_articles/create`,
          data: { unlockedArticleId: unlockedArticle.id },
        });

        setUrlShare(
          data != null
            ? `${window.origin}/share/${data.sharedArticle.identifier}`
            : window.origin
        );
      } catch (e) {
        toast.error('There was an error generating your shareable link.');
      }
    };
    loadInfo();
  }, [unlockedArticle]);

  const trackShareAction = (media: string) => {
    const HomeObject = 'home';

    if (!media) {
      return;
    }

    track(`${HomeObject}_article_share_${media}`, AnalyticsEvent.Primary, {
      articleId: unlockedArticle?.article.id || 0,
    });
  };

  const handleCopyAction = (
    copyURL: string = urlShare,
    successToast: string = 'Link copied to clipboard.',
    newTabURL: string | null = null
  ) => {
    if (copyURL) {
      navigator.clipboard.writeText(copyURL);
      toast.success(successToast);
      if (newTabURL) {
        setTimeout(() => {
          window.open(newTabURL, '_blank', 'noreferrer');
        }, 2000);
      }
    } else {
      toast.error('There was an error generating your shareable link.');
    }
  };

  const handleCopyLink = () => {
    trackShareAction('copy');
    handleCopyAction();
  };

  const slackClicked = () => {
    trackShareAction('slack');
    handleCopyAction(
      `${SHARE_MESSAGE}\n${urlShare}`,
      'Link copied to clipboard. Opening Slack.',
      'slack://open'
    );
  };

  const discordClicked = () => {
    trackShareAction('discord');
    handleCopyAction(
      `${SHARE_MESSAGE}\n${urlShare}`,
      'Link copied to clipboard. Opening Discord.',
      'discord://open'
    );
  };

  return (
    <>
      <Modal show={show} onHide={onHide} centered>
        <Modal.Body>
          <div className={styles.shareContainer}>
            <img
              className={styles.imgThumbnail}
              src={unlockedArticle?.article?.thumbnail}
              alt="Article Thumbnail"
            />
            <div
              className={classNames(styles.imgThumbnail, styles.opacityBox)}
            ></div>
            <div className={styles.textContainer}>
              <h2>Share article with friends</h2>
              <span>
                Share this article with your friends, and{' '}
                <span>earn a free credit </span> for each friend that signs up
                to Zette for the first time.
              </span>
            </div>
            <button className={styles.btnClose} onClick={onHide}>
              <X size={62} color={'#000'} />
            </button>
          </div>
          <div className={styles.urlBox}>
            <div>{urlShare}</div>
            <button onClick={handleCopyLink}>Copy</button>
          </div>

          <div className={styles.separator}>or share via</div>
        </Modal.Body>
        <Modal.Footer className={styles.footerBtns}>
          <EmailShareButton
            onClick={() => trackShareAction('email')}
            className={classNames(styles.btnShareSocial, styles.btnEmail)}
            url={urlShare}
            subject={SHARE_TITLE}
            body={SHARE_MESSAGE}
          >
            <EnvelopeFill size={16} color={'#000'} />
          </EmailShareButton>

          <TwitterShareButton
            onClick={() => trackShareAction('twitter')}
            className={classNames(styles.btnShareSocial, styles.btnTwitter)}
            url={urlShare}
            hashtags={['Zette', 'ZetteArticle']}
            title={TWITTER_SHARE_MESSAGE}
          >
            <Twitter size={16} color={'#FFF'} />
          </TwitterShareButton>

          <FacebookShareButton
            onClick={() => trackShareAction('facebook')}
            className={classNames(styles.btnShareSocial, styles.btnFacebook)}
            url={urlShare}
            quote={SHARE_MESSAGE}
            hashtag="#Zette"
          >
            <Facebook size={16} color={'#FFF'} />
          </FacebookShareButton>

          <RedditShareButton
            onClick={() => trackShareAction('reddit')}
            className={classNames(styles.btnShareSocial, styles.btnReddit)}
            url={urlShare}
            title={SHARE_MESSAGE}
          >
            <Reddit size={16} color={'#FFF'} />
          </RedditShareButton>

          <LinkedinShareButton
            onClick={() => trackShareAction('linkedin')}
            className={classNames(styles.btnShareSocial, styles.btnlinkedin)}
            url={urlShare}
            summary={SHARE_MESSAGE}
            title={SHARE_TITLE}
          >
            <Linkedin size={16} color={'#FFF'} />
          </LinkedinShareButton>

          <Button
            className={classNames(styles.btnShareSocial, styles.btnslack)}
            onClick={() => slackClicked()}
          >
            <Slack size={16} color={'#FFF'} />
          </Button>

          <Button
            className={classNames(styles.btnShareSocial, styles.btndiscord)}
            onClick={() => discordClicked()}
          >
            <Discord size={16} color={'#FFF'} />
          </Button>

          <Button
            className={classNames(styles.btnShareSocial, styles.btnteams)}
            onClick={() => trackShareAction('teams')}
          >
            <div
              className="teams-share-button"
              data-href={urlShare}
              data-icon-px-size="32"
            ></div>
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}
