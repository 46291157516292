import { useState, useEffect } from 'react';

import { snakeCase, camelCase } from 'lodash';
import { fetchData } from './global_functions';

// Custom hook that fetches paginated data from a given collection on the server.
// It assumes a naming convention for db collecitons, endpoints and response keys
// e.g. for a collection named 'ReadArticles' it will fetch from `collectionName`
// and the response will be in the form { readArticles: [...], hasNextPage: ... }.
// The endpoint should also accept a pageNum and pageSize query params
export const usePaginatedCollection = (collectionName, pageSize = 10) => {
  const endpoint = snakeCase(collectionName);
  const resultKey = camelCase(collectionName);

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [error, setError] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  const [lastPageFetched, setLastPageFetched] = useState(0);

  useEffect(() => {
    if (pageNum <= lastPageFetched) return;

    const abortController = new AbortController();
    setLoading(true);

    fetchData({
      url: `/${endpoint}`,
      params: { pageNum, pageSize },
      signal: abortController.signal,
    })
      .then(({ data }) => {
        setItems((prevItems) => [...prevItems, ...data[resultKey]]);
        setHasNextPage(data.hasNextPage || false);
        setLastPageFetched(pageNum);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });

    return () => abortController.abort();
  }, [pageNum]);

  const loadMore = () => {
    setPageNum((prevPage) => prevPage + 1);
  };

  return { loading, items, hasNextPage, error, loadMore };
};
