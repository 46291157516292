import React from 'react';
import Image from 'next/image';
import styles from '../../styles/Loading.module.scss';
import zetteIcon from '../../public/img/zetteIcon.svg';

const Loading = () => {
  return (
    <div className={styles.loadingContainer}>
      <div className={styles.loadingContents}>
        <Image height="44px" width="44px" src={zetteIcon} />
        <div className={`${styles.loadingBarBackground} m-4`}>
          <div className={`${styles.loadingBar}`}></div>
        </div>
        <p className={styles.loadingText}>Loading...</p>
      </div>
    </div>
  );
};

export default Loading;
