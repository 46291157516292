import classNames from "classnames";
import { Publication } from '../../additional';

import styles from "../../styles/publications/supportedPublications.module.scss";

interface Props {
  publications: Array<Publication>;
}

const DEFAULT_ORDER = -1;
const PREFERRED_PUBLISHER_ORDER = {
  'Forbes': 8,
  'The Information': 7,
  'TechCrunch': 6,
  'Miami Herald': 5,
  'The Sacramento Bee': 4,
  'Haaretz': 3,
  'New Scientist': 2,
};

export default function SupportedPublications({ publications }: Props) {
  const sortedPublications = publications.sort((pub1, pub2) => {
    const pub1Value = PREFERRED_PUBLISHER_ORDER[pub1.name] || DEFAULT_ORDER;
    const pub2Value = PREFERRED_PUBLISHER_ORDER[pub2.name] || DEFAULT_ORDER;

    return (pub1Value <= pub2Value) ? 1 : -1;
  });

  return (
    <div className={classNames(styles.mainContainer, "d-flex", "flex-wrap", "my-5")}>
      {sortedPublications.map(publication => {
        let publicationUrl = publication.domainName;

        if (!publicationUrl.startsWith("http://") && !publicationUrl.startsWith("https://")) {
          publicationUrl = `https://${publicationUrl}`;
        }

        return (
          <a
            href={publicationUrl}
            rel='noopener noreferrer'
            className={classNames(styles.link, 'me-3', 'mb-3')}
            target='_blank'
            key={publication.id}
          >
            <div className={classNames(styles.container, 'd-flex', 'justify-content-center', 'align-items-center', 'p-3')}>
              {publication.logo &&
                <div className='d-flex flex-column justify-content-center align-items-center'>
                  <div
                    style={{ backgroundImage: `url('${publication.logo.url}')` }}
                    className={classNames(styles.image, 'mb-2')}
                  />

                  <span className='text-center'>{publication.name}</span>
                </div>
              }

              {!publication.logo &&
                <span>{publication.name}</span>
              }
            </div>
          </a>
        )
      })}
    </div>
  );
}
