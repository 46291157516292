import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useHookstate } from '@hookstate/core';
import { Row, Card, Col, Tabs, Tab, SSRProvider } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import classNames from 'classnames';

import { fetchData, postData } from '../src/shared/global_functions';
import GlobalState from '../src/shared/states/global_state';

import Loading from '../src/components/loading';
import StyleIndex from '../styles/styleSpecialIndex';
import { track, AnalyticsEvent, identify } from '../src/shared/analytics';
import ArticleCard from '../src/components/ArticleCard';
import { PermittedUnlockableArticle, Publication, User, UnlockedArticle } from '../additional';
import ShareModal from '../src/components/ShareModal';
import { useUnlockedArticles } from '../src/shared/useUnlockedArticles';
import SupportedPublications from '../src/publications/supportedPublications';

import styles from "../styles/Home.module.scss";

const HomeObject = 'home';

export default function Home() {
  const globalState = useHookstate(GlobalState);
  const [shareModalUnlockedArticle, setShareModalUnlockedArticle] = useState<UnlockedArticle>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [supportedPublications, setSupportedPublications] = useState<Array<Publication>>([]);
  const [permittedArticles, setPermittedArticles] = useState<Array<PermittedUnlockableArticle>>([]);

  const unlockedArticlesStore = useUnlockedArticles();

  const bookmarkedArticles = unlockedArticlesStore.items.filter(article => article.bookmarked);

  const { user } = globalState.get();
  const router = useRouter();
  //Create a component wide abort controller, and return
  //a function from a `useEffect` with no dependencies
  //that aborts it.
  const abortController = new AbortController();

  const updateUser = async () => {
    const response = await fetchData({
      url: '/current_user',
      signal: abortController.signal,
    });

    const { data } = response;

    if (data.user) {
      globalState.merge({
        user: data.user,
        userInterests: data.userInterests,
        userProfileImage: data.userProfileImage,
        creditsRemaining: parseInt(data.creditsRemaining, 10),
      });
    }

    return response;
  };

  const selectLightReaderPlan = async (user: User) => {
    const settings = await fetchData({
      url: `/users/${user.id}/settings`,
      signal: abortController.signal,
    });

    const { subscription } = settings.data;

    if (!subscription) {
      const allPlans = settings.data.subscriptionPlans || [];
      const plans = allPlans.filter((elem) => elem.frequency == 'Monthly');

      if (plans && plans.length > 0) {
        const planId = plans[0].id;

        //If you want to use it, just uncomment this next line
        //const subscription =
        await postData({
          url: '/subscriptions/create_subscription',
          data: { planId },
          signal: abortController.signal,
        });

        await updateUser();
      }
    }
    await unlockSharedArticle();
  };

  const unlockSharedArticle = async () => {
    // For v1 of sharing we just redirect away from zette to the article url
    // A fast-follow will include showing unlocked shared articles in home

    const sharedArticleId = localStorage.getItem('unlockSharedArticle');
    if (!sharedArticleId) return;

    try {
      const { data } = await postData({
        url: `/shared_articles/${sharedArticleId}/unlock`,
        signal: abortController.signal,
      });
      localStorage.removeItem('unlockSharedArticle');
      localStorage.setItem(`onboarding_${user.id}`, user ? 'ok' : undefined);
      if (data.unlockedArticle?.url) {
        window.open(data.unlockedArticle.url, '_blank');
      }
    } catch (error) {
      toast.error('There was an error opening your shared article.');
    }
  };

  const getAndProcessUserData = async () => {
    const { data } = await updateUser();

    if (!data.user) {
      //Don't remove the loading indicator in this instance
      router.push('/signin');
      return;
    } else {
      identify(data.user);
      track(HomeObject, AnalyticsEvent.View);

      await selectLightReaderPlan(data.user);

      viewOnboardingIfNecesssary(data.user);
    }
  };

  const viewOnboardingIfNecesssary = (user: User) => {
    const onboardingItem = localStorage.getItem(`onboarding_${user.id}`);
    if (!onboardingItem) {
      router.push('/onboarding');
    }
  };

  const loadingWrapper = async (functions: (() => void)[]) => {
    setIsLoading(true);
    await Promise.all(functions.map((fun) => fun()));
    setIsLoading(unlockedArticlesStore.loading);
  };

  const onArticleClick = async (e, readArticle) => {
    setIsLoading(true);
    e.preventDefault();

    track(`${HomeObject}_article`, AnalyticsEvent.Primary);

    try {
      const { data } = await fetchData({
        url: `/read_article/${readArticle.id}`,
        signal: abortController.signal,
      });

      if (data.redirectUrl) {
        window.open(data.redirectUrl, '_blank');
      }
    } catch (e) {
      // The user can try clicking the link again
    }
    setIsLoading(false);
  };

  const initialize = async () => {
    await loadingWrapper([getAndProcessUserData]);
  };

  useEffect(() => {
    initialize();

    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    const { sharePopupArticleId } = router.query;

    const fetchArticle = async () => {
      setIsLoading(true);
      try {
        const { data } = await fetchData({
          //This should eventually be /unlocked_article/:id
          //but that's not supported now and instead
          // /read_article/:id fetches unlocked articles
          url: `/read_article/${sharePopupArticleId}`,
          signal: abortController.signal,
        });

        if (data) {
          setShareModalUnlockedArticle(data);
        }
      } catch (e) {
        //NoOp
      }
      setIsLoading(false);
    };

    if (sharePopupArticleId) {
      fetchArticle();
    }
  }, [router.isReady]);

  useEffect(() => {
    const fetchSupportedPublications = async () => {
      try {
        const { data } = await fetchData({
          url: '/publications/supported',
          signal: abortController.signal,
        });

        if (data) {
          setSupportedPublications(data.publications);
        }
      } catch (e) {
        //NoOp
      }
    };

    fetchSupportedPublications();
  }, []);

  useEffect(() => {
    const fetchPermittedArticles = async () => {
      try {
        const { data } = await fetchData({
          url: '/permitted_articles',
          params: { publisherName: "Financial Times", publicationName: "Financial Times" },
          signal: abortController.signal,
        });

        if (data) {
          setPermittedArticles(data.permittedArticles);
        }
      } catch (e) {
        //NoOp
      }
    };

    fetchPermittedArticles();
  }, []);

  async function handleDeleteBookmark(unlockedArticle: UnlockedArticle) {
    try {
      track(`${HomeObject}_article_bookmark`, AnalyticsEvent.Secondary, {
        articleId: unlockedArticle?.article?.id,
      });

      if (!unlockedArticle.bookmarked) {
        throw new Error('This article is not bookmarked.');
      }

      unlockedArticlesStore.deleteBookmarkedArticle(unlockedArticle.id);
    } catch (e) { }
  }

  async function handleBookmark(unlockedArticle: UnlockedArticle) {
    try {
      track(`${HomeObject}_article_bookmark`, AnalyticsEvent.Primary, {
        articleId: unlockedArticle?.article?.id,
      });
      unlockedArticlesStore.createBookmarkedArticle(unlockedArticle.id);
    } catch (e) { }
  }

  const onShare = async (unlockedArticle: UnlockedArticle) => {
    track(`${HomeObject}_article_share`, AnalyticsEvent.Primary, {
      articleId: unlockedArticle.article?.id,
    });
    setShareModalUnlockedArticle(unlockedArticle);
  };

  return (
    <SSRProvider>
      <StyleIndex />

      <Head>
        <title>Zette</title>
      </Head>

      <main className="mt-4">
        {isLoading && <Loading />}

        {!isLoading && permittedArticles.length > 0 &&
          <div className="mb-4">
            {<div className={`${styles.title} mb-4`}>Today's Articles from Financial Times</div>}

            <div className="d-flex flex-wrap">
              {permittedArticles.map((permittedArticle, index) => (
                <Card key={index} className="w-25 me-2 mb-2">
                  <a href={permittedArticle.article?.url} className={styles.articleLink} rel="noopener noreferrer" target="_blank">
                    <Card.Body className="d-flex">
                      <div className={classNames(styles.permittedArticleNumber, "me-2", "flex-shrink-0")}>
                        {index + 1}
                      </div>

                      <div className={styles.permittedArticleTitle}>{permittedArticle.article?.title}</div>
                    </Card.Body>
                  </a>
                </Card>
              ))}
            </div>
          </div>
        }

        {!isLoading && unlockedArticlesStore.items.length > 0 && (
          <>
            {<div className={`${styles.title} mb-4`}>Your Reading History</div>}
            <Tabs
              id="navigation-tabs-all"
              defaultActiveKey="all"
              style={{ marginBottom: 48 }}
            >
              <Tab eventKey="all" title="All">
                <Row className="mb-5">
                  {unlockedArticlesStore.items.map((unlockedArticle, index) => {
                    return (
                      <Col key={index} sm={6} md={4} lg={3} className="mb-5">
                        <ArticleCard
                          unlockedArticle={unlockedArticle}
                          onClick={(e) => onArticleClick(e, unlockedArticle)}
                          deleteBookmarkedArticle={() =>
                            handleDeleteBookmark(unlockedArticle)
                          }
                          createBookmarkedArticle={() =>
                            handleBookmark(unlockedArticle)
                          }
                          onShare={onShare}
                        />
                      </Col>
                    );
                  })}
                  <div className={styles.loadMoreContainer}>
                    {unlockedArticlesStore.loading && <Loading />}
                    {!unlockedArticlesStore.loading &&
                      unlockedArticlesStore.hasNextPage && (
                        <button
                          className="rounded-pill pt-1 px-3 mx-3 btn btn-outline-secondary btn-sm"
                          onClick={unlockedArticlesStore.loadMore}
                        >
                          Load More
                        </button>
                      )}
                  </div>
                </Row>
              </Tab>

              <Tab
                eventKey="bookmarked"
                title={`Bookmarked (${bookmarkedArticles.length})`}
              >
                <Row className="mb-5">
                  {bookmarkedArticles.map((article, index) => {
                    return (
                      <Col key={index} sm={6} md={4} lg={3} className="mb-5">
                        <ArticleCard
                          unlockedArticle={article}
                          onClick={(e) => onArticleClick(e, article)}
                          deleteBookmarkedArticle={() =>
                            handleDeleteBookmark(article)
                          }
                          createBookmarkedArticle={() =>
                            handleBookmark(article)
                          }
                          onShare={onShare}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </Tab>
            </Tabs>
          </>
        )}

        {!isLoading && unlockedArticlesStore.items.length == 0 && supportedPublications.length > 0 && (
          <div>
            <h3 className={styles.emptyStateTitle}>Unlock your first article!</h3>

            <p className={styles.emptyStateSubtitle}>Here's a list of publications you can unlock articles from using Zette</p>

            <SupportedPublications publications={supportedPublications} />
          </div>
        )}

        <ShareModal
          show={shareModalUnlockedArticle != null}
          unlockedArticle={shareModalUnlockedArticle}
          onHide={async () => {
            setShareModalUnlockedArticle(null);
          }}
        />
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </main>
    </SSRProvider>
  );
}
