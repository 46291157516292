import React from 'react';
import Card from 'react-bootstrap/Card';
import Image from 'next/image';
import bookmarkedIcon from '../../public/bookmarked-icon.png';
import notBookmarkedIcon from '../../public/non-bookmarked-icon.svg';
import shareIcon from '../../public/share-icon.svg';
import styles from '../../styles/components/ArticleCard.module.scss';
import { UnlockedArticle } from '../../additional';

export interface ArticleCardProps {
  unlockedArticle: UnlockedArticle;
  onClick: (any) => Promise<void>;
  deleteBookmarkedArticle: (any) => Promise<void>;
  createBookmarkedArticle: (any) => Promise<void>;
  onShare: (UnlockedArticle) => Promise<void>;
}

export default function ArticleCard({
  unlockedArticle,
  onClick,
  deleteBookmarkedArticle,
  createBookmarkedArticle,
  onShare,
}: ArticleCardProps) {
  const handleShareClick = () => {
    onShare(unlockedArticle);
  };

  return (
    <>
      <Card>
        <a href="#" className={styles.articleLink} onClick={onClick}>
          <Card.Img
            className={styles.cardImg}
            src={unlockedArticle.article?.thumbnail}
          />
        </a>

        <Card.Body>
          <a href="#" className={styles.articleLink} onClick={onClick}>
            <div className={`${styles.articleTitle}`}>
              {unlockedArticle.article?.title}
            </div>
          </a>
        </Card.Body>

        <Card.Body className="border-top d-flex justify-content-between">
          <div
            className={`${styles.cursorCreateBookmarkedArticle} align-items-center`}
            onClick={
              unlockedArticle.bookmarked
                ? deleteBookmarkedArticle
                : createBookmarkedArticle
            }
          >
            <Image
              src={
                unlockedArticle.bookmarked ? bookmarkedIcon : notBookmarkedIcon
              }
            />
            <span className="ms-2">
              {unlockedArticle.bookmarked ? 'Bookmarked' : 'Bookmark'}
            </span>
          </div>
          <a href="#" className={styles.shareButton} onClick={handleShareClick}>
            <Image src={shareIcon} />
            <span className="ms-2">Share</span>
          </a>
        </Card.Body>
      </Card>
    </>
  );
}
