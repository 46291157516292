import { patchData } from './global_functions';
import { usePaginatedCollection } from './usePaginatedCollection';
import { useEffect, useState } from 'react';

export const useUnlockedArticles = () => {
  const {
    loading,
    items: collectionItems,
    hasNextPage,
    error,
    loadMore,
  } = usePaginatedCollection('UnlockedArticles', 12);

  const [items, setItems] = useState(collectionItems);

  useEffect(() => {
    setItems(collectionItems);
  }, [collectionItems]);

  const patchUnlockedArticle = async (unlockedArticleId, payload) => {
    const { data } = await patchData({
      url: `/unlocked_articles/${unlockedArticleId}`,
      data: payload,
    });

    const item = items.find((item) => item.id === unlockedArticleId);
    Object.assign(item, data.unlockedArticle);
    setItems([...items]);
  };

  const createBookmarkedArticle = async (unlockedArticleId) => {
    await patchUnlockedArticle(unlockedArticleId, { bookmarked: true });
  };

  const deleteBookmarkedArticle = async (unlockedArticleId) => {
    await patchUnlockedArticle(unlockedArticleId, { bookmarked: false });
  };

  return {
    loading,
    items,
    hasNextPage,
    error,
    loadMore,
    createBookmarkedArticle,
    deleteBookmarkedArticle,
  };
};
